<template>
  <v-container fluid>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Filters</v-expansion-panel-header>
        <v-expansion-panel-content class="my-3">
          <v-row dense>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="options.startDate"
                    label="Start Date"
                    readonly
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.startDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="options.endDate"
                    label="End Date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.endDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="options.ObjType" row mandatory>
                <v-radio label="Invoices" value="13"></v-radio>
                <v-radio label="Orders" value="17"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="options.selectedItem"
                :items="salesEmployees"
                outlined
                dense
                attach
                chips
                clearable
                deletable-chips
                label="Item Groups"
                item-text="ItmsGrpNam"
                item-value="ItmsGrpCod"
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="report"
          :search="search"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      report: [],
      startDate: null,
      endDate: null,
      selectedItem: null,
      salesEmployees: [],
      options: {
        startDate: null,
        endDate: null,
        selectedItem: [],
        ObjType: null,
      },
      headers: [
        { text: "Item No.", value: "ItemCode" },
        { text: "Item Description", value: "ItemName" },
        { text: "Quantity", value: "Quantity" },
        { text: "Sales Amt", value: "AllLineTotal" },
        { text: "Gross Profit", value: "GrssProfit" },
        { text: "Gross Profit %", value: "OpenQty" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getOrders();
        this.defaultDates();
      },
      deep: true,
    },
  },
  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        this.options.startDate = firstDayOfMonth.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const lastDayOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
        this.options.endDate = lastDayOfMonth.toISOString().substr(0, 10);
      }
    },
    getOrders() {
      const self = this;
      this.loading = true;
      let url = `/items_report`;
      if (this.options.startDate) {
        url += `?startdate=${this.options.startDate}`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}endate=${this.options.endDate}`;
      }
      if (this.options.ObjType) {
        url += `${url.includes("?") ? "&" : "?"}ObjType=${
          this.options.ObjType
        }`;
      }

      if (this.options.selectedItem.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }ItemCodes=${this.options.selectedItem
          .map((p) => `${encodeURIComponent(p)}`)
          .join(",")}`;
      }
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.report = res.ResponseData.data;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    updateRange() {
      if (
        this.options.startDate &&
        this.options.endDate &&
        this.options.startDate > this.options.endDate
      ) {
        this.$refs.snackbar.show(
          "Start date cannot be greater than End date",
          "red"
        );

        this.options.endDate = "";
      }
    },
  },
  created() {
    this.getOrders();
    this.getEmployees();
    this.defaultDates();
  },
};
</script>
  
  <style lang="scss" scoped>
</style>

